import React, { useState } from "react";
import "./bookingMobile.css";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../utils/getTranslation";
import Button from "../Button/Button";
import { useDispatch } from "react-redux";
import {
  getTestDrives,
  setCurrentPage,
  setLoading,
  setToReschedule,
  toggleNotification
} from "../../redux-sagas/actions";
import fetchClient from "../../services/fetch";
import ConfirmationModal from "../Modal/ConfirmationModal/ConfirmationModal";
import { formatCurrentDate } from "../../utils/formatDateTime";
import { format } from "date-fns";
import { getMyTestDriveParams } from "../../utils/getParams";

const BookingMobile = ({ booking, payload }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showCancelModal, setShowCancelModal] = useState(false);

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };

  const toggleAcceptModal = () => {
    setShowCancelModal(false);
    dispatch(setLoading(true));
    fetchClient()
      .put(`/testdrives/${booking.bookingId}/status/cancel`)
      .then(() => {
        dispatch(setLoading(false));
        dispatch(
          toggleNotification({
            isOpen: true,
            type: "success",
            content1: getTranslation("The", t),
            highlight1: "Test Drive",
            content2: getTranslation("has been successfully canceled.", t),
            highlight2: ""
          })
        );
        dispatch(getTestDrives(getMyTestDriveParams(payload)));
      })
      .catch((err) => console.log(err));
  };

  const handleRescheduleClick = () => {
    dispatch(setToReschedule(booking));
    dispatch(setCurrentPage("schedule"));
  };

  return (
    <div className='booking-mobile'>
      <img
        className='mb-4'
        src={booking.vehicleImageUrl}
        alt={`${booking.vehicle} icon`}
      />
      <div className='booking-info-content row p-5 pt-0 pb-3 m-0'>
        <div className='booking-info col-6 p-0 pe-3 mb-4'>
          <span>{getTranslation("Branch", t)}</span>
          <span>{booking.showroomName}</span>
        </div>
        <div className='booking-info col-6 p-0 ps-3 mb-4'>
          <span>{getTranslation("Date", t)}</span>
          <span>{format(new Date(booking.bookingDate), "dd/MM/yyyy")}</span>
        </div>
        <div className='booking-info col-6 p-0 pe-3 mb-4'>
          <span>{getTranslation("Start Time", t)}</span>
          <span>{booking.startTimeFormatted}</span>
        </div>
        <div className='booking-info col-6 p-0 ps-3 mb-4'>
          <span>{getTranslation("End Time", t)}</span>
          <span>{booking.endTimeFormatted}</span>
        </div>
        <div className='booking-info col-6 p-0 pe-3 mb-4'>
          <span>{getTranslation("Model", t)}</span>
          <span>{booking.modelName}</span>
        </div>
        <div className='booking-info col-6 p-0 ps-3 mb-4'>
          <span>{getTranslation("Variant", t)}</span>
          <span>{booking.variantName}</span>
        </div>
      </div>
      <div className='gap-2 d-flex justify-content-between w-100'>
        <Button
          disabled={formatCurrentDate(new Date()) > formatCurrentDate(booking.bookingDateFormatted)}
          type='secondary'
          text={getTranslation("Cancel", t)}
          handleClick={toggleCancelModal}
        />
        <Button
          disabled={formatCurrentDate(new Date()) > formatCurrentDate(booking.bookingDateFormatted)}
          type='primary'
          text={getTranslation("Re-schedule", t)}
          handleClick={handleRescheduleClick}
        />
        <ConfirmationModal
          handleAccept={toggleAcceptModal}
          handleBack={toggleCancelModal}
          open={showCancelModal}
          text={getTranslation("Are you sure about canceling your Test Drive?", t)}
          title={getTranslation("Cancel Test Drive", t)}
        />
      </div>
    </div>
  );
};

export default BookingMobile;
