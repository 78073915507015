import { useEffect, useRef, useState } from "react";
import ManageTestDriveBookings from "./ManageTestDriveBookings/ManageTestDriveBookings";
import ManageTestDriveForm from "./ManageTestDriveForm/ManageTestDriveForm";
import "./manageTestDrive.css";
import { useDispatch, useSelector } from "react-redux";
import { getTestDrives, getDocumentTypes } from "../../redux-sagas/actions";
import useValidateForm from "../../hooks/useValidateForm";
import { getMyTestDriveParams } from "../../utils/getParams";

const ManageTestDrive = () => {
  const dispatch = useDispatch();
  const userTestDriveInfo = useSelector((state) => state.userTestDriveInfo);
  const [payload, setPayload] = useState(
    Object.keys(userTestDriveInfo).length
      ? { ...userTestDriveInfo, documentNumber: userTestDriveInfo.documentNum }
      : {}
  );
  // Use Validation Hook
  const { errors, validateOnChange, validateOnSubmit, isSubmitEnabled } = useValidateForm();
  const isSubmitted = useRef(false);

  useEffect(() => {
    if (Object.keys(userTestDriveInfo).length) {
      dispatch(getDocumentTypes(""));
      fetchTestDriveData();
    }
  }, []);

  const fetchTestDriveData = () =>
    // Get Test Drives
    dispatch(getTestDrives(getMyTestDriveParams(payload)));

  return (
    <div className='manage-drive'>
      <ManageTestDriveForm
        isSubmitted={isSubmitted}
        errors={errors}
        validateOnChange={validateOnChange}
        validateOnSubmit={validateOnSubmit}
        isSubmitEnabled={isSubmitEnabled}
        payload={payload}
        setPayload={setPayload}
        fetchTestDriveData={fetchTestDriveData}
      />
      <ManageTestDriveBookings
        errors={errors}
        isSubmitted={isSubmitted}
        payload={payload}
      />
    </div>
  );
};

export default ManageTestDrive;
