import { getTranslation } from "./getTranslation";
import store from "../redux-sagas/store";
import i18next from "i18next";

const getDocumentNumberLabel = (documentTypeId) => {
	const documentTypes = store.getState().documentTypes;
	const documentType = documentTypes.data?.find((d) => d.id === documentTypeId);
	const name = documentType?.description ?? documentType?.name ?? "Document number";

	return getTranslation(name, i18next.t);
};

export default getDocumentNumberLabel;
