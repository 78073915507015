import fetchClient from "../../services/fetch";

export const requestTestDriveApi = (params) =>
  fetchClient().post("/testdrives/request", params);

export const getShowroomListApi = (params) =>
  fetchClient().get("/commondata/showrooms" + params);

export const getDealerListApi = (params) =>
  fetchClient().get("/commondata/dealers" + params);

export const getVehicleListApi = (params) =>
  fetchClient().get("/vehicles" + params);

export const getShowroomFiltersApi = (params) =>
  fetchClient().get("/filters/showrooms" + params);

export const getModelFiltersApi = (params) =>
  fetchClient().get("/filters/models" + params);

export const getDocumentTypesApi = (params) =>
  fetchClient().get("/commondata/documenttypes" + params);

export const getTestDrivesApi = (params) =>
  fetchClient().get("/testdrives" + params);

export const loadPublicSettingsApi = (code) =>
  fetchClient().get(`/markets?code=${code}`);

export const getPublicSettingsApi = (params) =>
  fetchClient().get(`/settings${params}`);