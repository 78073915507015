import React from "react";
import "./hourButton.css";

const HourButton = ({ value, disabled, onClick, scheduled }) => {
  return (
    <>
      {/* TODO ITEMS FOR DYNAMIC USE*/}
      <button
        onClick={onClick}
        className={`hour-btn ${scheduled ? "scheduled" : ""}`}
        disabled={disabled || false}
      >
        {value}
      </button>
    </>
  );
};

export default HourButton;
