import React from "react";
import "./confirmationModal.css";
import { Dialog } from "@mui/material";
import Button from "../../Button/Button";

const ConfirmationModal = ({ handleAccept, handleBack, open, text, title }) => {
  return (
    <Dialog className="confirm" aria-labelledby="confirm-modal" open={open}>
      <h4>{title}</h4>
      <span className="confirm-text">{text}</span>
      <div className="btn-group">
        <Button type="secondary" text="Return" handleClick={handleBack} />
        <Button type="primary" text="Accept" handleClick={handleAccept} />
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
