import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "bootstrap/dist/css/bootstrap.css";
import "./fonts.css";
import "./index.css";
import "./custom.css";
import { Provider } from "react-redux";
import store from "./redux-sagas/store";

// Translation feature
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import { TDB_LANGUAGE_KEY } from "./utils/constants";

i18n
	.use(initReactI18next)
	.use(HttpBackend)
	.init({
		supportedLngs: ["en", "es"],
		// debug: true,
		fallbackLng: "es",
		interpolation: {
			escapeValue: false
		},
		lng: localStorage.getItem(TDB_LANGUAGE_KEY) || "es"
	});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>
);
