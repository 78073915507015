import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_DEALER_LIST } from "../actions/types";
import { setDealerList } from "../actions";
import { getDealerListApi } from "../api/handler";

function* handleGetDealerList(args) {
  try {
    const response = yield call(getDealerListApi, args.params);
    if (response.status === 200) {
      yield put(setDealerList(response.data));
    }
  } catch (err) {
    console.warn(err);
  }
}

export default function* dealerSaga() {
  yield all([takeLatest(GET_DEALER_LIST, handleGetDealerList)]);
}
