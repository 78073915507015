import { takeLatest, all, call, put } from "redux-saga/effects";
import { getShowroomFiltersApi } from "../api/handler";
import { GET_SHOWROOM_FILTERS } from "../actions/types";
import { setLoading, setShowroomFilters } from "../actions/index";
import store from "../store/index";

function* handleGetShowroomFilters(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getShowroomFiltersApi, args.params);
    if (response.status === 200) {
      yield put(setShowroomFilters(response.data));
    }
  } catch (err) {
    console.warn(err);
  }
  if (Object.keys(store.getState().vehicleList).length !== 1)
    yield put(setLoading(false));
}

export default function* showroomFiltersSaga() {
  yield all([takeLatest(GET_SHOWROOM_FILTERS, handleGetShowroomFilters)]);
}
