import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_TEST_DRIVES, REQUEST_TEST_DRIVE } from "../actions/types";
import { getTestDrivesApi, requestTestDriveApi } from "../api/handler";
import { setLoading, setTestDrives } from "../actions";

function* handleRequestTestDrive(args) {
  try {
    const response = yield call(requestTestDriveApi, args.params);
    if (response.status === 200) {
      console.log("Request Test Drive Success");
    }
  } catch (e) {
    console.warn(e);
  }
}

function* handleGetTestDrives(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getTestDrivesApi, args.params);
    if (response.status === 200) {
      yield put(setTestDrives(response.data));
    }
  } catch (e) {}
  yield put(setLoading(false));
}

export default function* testDriveSaga() {
  yield all([
    takeLatest(REQUEST_TEST_DRIVE, handleRequestTestDrive),
    takeLatest(GET_TEST_DRIVES, handleGetTestDrives),
  ]);
}
