import {
	SET_MODEL_FILTERS,
	SET_CURRENT_PAGE,
	SET_LOADING,
	TOGGLE_NOTIFICATION,
	SET_SHOWROOM_FILTERS,
	SET_SHOWROOM_LIST,
	SET_TO_RESCHEDULE,
	SET_VEHICLE_LIST,
	SET_DOCUMENT_TYPES,
	SET_TEST_DRIVES,
	SET_USER_TEST_DRIVE_INFO,
	SET_PUBLIC_SETTINGS,
	SET_DEALER_LIST
} from "../actions/types";

const initialState = {
	settings: null,
	isLoading: false,
	notification: {
		isOpen: false,
		type: "",
		content1: "",
		content2: "",
		highlight1: "",
		highlight2: ""
	},
	vehicleList: {
		data: undefined
	},
	showroomFilters: {
		data: []
	},
	modelFilters: {
		data: []
	},
	showroomList: {
		totalRecords: 0,
		totalPages: 0,
		data: []
	},
	dealerList: {
		data: []
	},
	toReschedule: null,
	currentPage: "schedule",
	testDrives: {
		data: []
	},
	documentTypes: {
		data: []
	},
	userTestDriveInfo: {}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LOADING:
			return { ...state, isLoading: action.isLoading };
		case SET_PUBLIC_SETTINGS:
			return { ...state, settings: action.settings };
		case TOGGLE_NOTIFICATION:
			return { ...state, notification: action.notificationValues };
		case SET_SHOWROOM_LIST:
			return { ...state, showroomList: action.showroomList };
		case SET_DEALER_LIST:
			return { ...state, dealerList: action.dealerList };
		case SET_VEHICLE_LIST:
			return { ...state, vehicleList: action.vehicleList };
		case SET_SHOWROOM_FILTERS:
			return { ...state, showroomFilters: action.showroomFilters };
		case SET_MODEL_FILTERS:
			return { ...state, modelFilters: action.modelFilters };
		case SET_TO_RESCHEDULE:
			return { ...state, toReschedule: action.newSchedule };
		case SET_CURRENT_PAGE:
			return { ...state, currentPage: action.newPage };
		case SET_DOCUMENT_TYPES:
			return { ...state, documentTypes: action.documentTypes };
		case SET_TEST_DRIVES:
			return { ...state, testDrives: action.testDrives };
		case SET_USER_TEST_DRIVE_INFO:
			return { ...state, userTestDriveInfo: action.userTestDriveInfo };
		default:
			return state;
	}
};

export default reducer;
