export const SET_LOADING = "SET_LOADING";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";

export const REQUEST_TEST_DRIVE = "REQUEST_TEST_DRIVE";

export const GET_SHOWROOM_LIST = "GET_SHOWROOM_LIST";
export const SET_SHOWROOM_LIST = "SET_SHOWROOM_LIST";

export const GET_DEALER_LIST = "GET_DEALER_LIST";
export const SET_DEALER_LIST = "SET_DEALER_LIST";

export const GET_VEHICLE_LIST = "GET_VEHICLE_LIST";
export const SET_VEHICLE_LIST = "SET_VEHICLE_LIST";

export const GET_SHOWROOM_FILTERS = "GET_SHOWROOM_FILTERS";
export const SET_SHOWROOM_FILTERS = "SET_SHOWROOM_FILTERS";

export const GET_MODEL_FILTERS = "GET_MODEL_FILTERS";
export const SET_MODEL_FILTERS = "SET_MODEL_FILTERS";

export const SET_TO_RESCHEDULE = "SET_TO_RESCHEDULE";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const GET_DOCUMENT_TYPES = "GET_DOCUMENT_TYPES";
export const SET_DOCUMENT_TYPES = "SET_DOCUMENT_TYPES";

export const GET_TEST_DRIVES = "GET_TEST_DRIVES";
export const SET_TEST_DRIVES = "SET_TEST_DRIVES";

export const GET_USER_TEST_DRIVE_INFO = "GET_USER_TEST_DRIVE_INFO";
export const SET_USER_TEST_DRIVE_INFO = "SET_USER_TEST_DRIVE_INFO";

export const GET_PUBLIC_SETTINGS = "GET_PUBLIC_SETTINGS";
export const SET_PUBLIC_SETTINGS = "SET_PUBLIC_SETTINGS";
