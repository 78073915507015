import "./widget.css";
import Header from "../../layout/Header/Header";
import ScheduleTestDrive from "../ScheduleTestDrive/ScheduleTestDrive";
import ManageTestDrive from "../ManageTestDrive/ManageTestDrive";
import { MdInsertInvitation } from "react-icons/md";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setTestDrives,
  setToReschedule,
  setUserTestDriveInfo
} from "../../redux-sagas/actions";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import ThemeOverride from "./ThemeOverride";

const Widget = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentPage = useSelector((state) => state.currentPage);
  const isLoading = useSelector((state) => state.isLoading);
  const settings = useSelector((state) => state.settings);

  const strokeColor = currentPage !== "test-drives" 
    ? getComputedStyle(document.querySelector("body")).getPropertyValue("--primary") 
    : '#fff';

  return (
    <ThemeOverride>
      <div className="widget">
        <Header currentPage={currentPage} />
        <div className="container p-0">
          <nav>
            <ul>
              <li
                className={`${currentPage === "schedule" ? "selected" : "unselected"
                  }`}
                onClick={() => {
                  dispatch(setCurrentPage("schedule"));
                  dispatch(setUserTestDriveInfo({}));
                }}
              >
                <MdInsertInvitation />
                <span>{getTranslation("Schedule Test Drive", t)}</span>
              </li>
              <li
                className={`${currentPage === "test-drives" ? "selected" : "unselected"
                  }`}
                onClick={() => {
                  dispatch(setCurrentPage("test-drives"));
                  dispatch(setToReschedule(null));
                  dispatch(setTestDrives({ data: [] }));
                  dispatch(setUserTestDriveInfo({}));
                }}
              >
                {/* {currentPage === "test-drives" ? (
                  <img src={historyQueryAltIcon} alt="history query alt" />
                ) : (
                  <img src={historyQueryIcon} alt="history query " />
                )} */}
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.3115 12.3206L21.3115 4.82059C21.3115 4.42276 21.1535 4.04123 20.8722 3.75993C20.5909 3.47862 20.2093 3.32059 19.8115 3.32059L4.81152 3.32059C4.4137 3.32059 4.03217 3.47862 3.75086 3.75993C3.46956 4.04123 3.31152 4.42276 3.31152 4.82059L3.31152 19.8206C3.31152 20.2184 3.46956 20.5999 3.75086 20.8812C4.03217 21.1626 4.4137 21.3206 4.81152 21.3206L12.3115 21.3206" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M16.3115 19.3206C17.9684 19.3206 19.3115 17.9774 19.3115 16.3206C19.3115 14.6637 17.9684 13.3206 16.3115 13.3206C14.6547 13.3206 13.3115 14.6637 13.3115 16.3206C13.3115 17.9774 14.6547 19.3206 16.3115 19.3206Z" stroke={strokeColor} strokeWidth="2" />
                  <path d="M18.8115 18.3206L21.3115 20.3206M7.31152 8.32059L17.3115 8.32059M7.31152 12.3206L11.3115 12.3206" stroke={strokeColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>{getTranslation("My Test Drives", t)}</span>
              </li>
            </ul>
          </nav>
          <div className="main-cont">
            {
              settings && (
                currentPage === "schedule" ? (
                  <ScheduleTestDrive />
                ) : (
                  <ManageTestDrive />
                )
              )
            }
            {isLoading && <Loader />}
          </div>
        </div>
      </div>
    </ThemeOverride>
  );
};

export default Widget;
