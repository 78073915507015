import { formatDate } from "../../utils/formatDateTime";
import { subDays } from "date-fns";
import { getTranslation } from "../../utils/getTranslation";
import "./dateCard.css";
import { useTranslation } from "react-i18next";

const DateCard = ({ date, currentDate, handleChangeDate, defaultDate }) => {
  const { t } = useTranslation();
  const handleSelect = () => handleChangeDate(date.value, "selection");

  return (
    <div
      onClick={date.value >= subDays(defaultDate, 1) ? handleSelect : undefined}
      className={
        "date-card " +
        (formatDate(currentDate) === formatDate(date.value)
          ? "selected "
          : "") +
        (date.value >= subDays(defaultDate, 1) ? "" : "disabled")
      }
    >
      <span>{date.date}</span>
      <span className="day">{getTranslation(date.dayOfWeek, t)}</span>
    </div>
  );
};

export default DateCard;
