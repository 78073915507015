import { takeLatest, all, call, put } from "redux-saga/effects";
import { getVehicleListApi } from "../api/handler";
import { GET_VEHICLE_LIST } from "../actions/types";
import { setLoading, setVehicleList } from "../actions/index";

function* handleGetVehicleList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getVehicleListApi, args.params);
    if (response.status === 200) {
      yield put(setVehicleList(response.data));
    }
  } catch (err) {
    console.warn(err);
  }
  yield put(setLoading(false));
}

export default function* vehicleListSaga() {
  yield all([takeLatest(GET_VEHICLE_LIST, handleGetVehicleList)]);
}
