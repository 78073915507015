import { useTranslation } from "react-i18next";
import { getTranslation } from "../../utils/getTranslation";
import "./button.css";

const Button = ({ text, disabled, type, handleClick, className = "" }) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={handleClick}
      disabled={disabled || false}
      className={
        `button ${className} ` +
        (type === "primary" ? "primary-btn" : "secondary-btn")
      }
    >
      {getTranslation(text, t)}
    </button>
  );
};

export default Button;
