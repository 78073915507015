import { v4 as uuid } from "uuid";
import getUtmParams from "./getUtmParams";

const addToDataLayer = (settings, obj) => {
	if (settings && settings.enableGoogleAnalytics4) {
		const noValue = "No selected by user";

		let data = {
			event: "Test Drive",
			eventName: "TestDrive_Lead",
			model: obj.modelName ?? noValue,
			version: obj.variantName ?? noValue,
			dealerPreference: obj.showroomName ?? noValue,
			date: obj.dateFormatted ?? noValue,
			hour: obj.timeFormatted ?? noValue,
			transactionId: uuid()
		};

		const { dataLayerParam } = getUtmParams();
		if (dataLayerParam) data.urlParams = dataLayerParam;

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(data);
	}
};

export default addToDataLayer;
