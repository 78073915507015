import { all } from "redux-saga/effects";
import testDriveSaga from "./testDrive";
import showroomSaga from "./showroom";
import vehicleListSaga from "./vehicleList";
import modelFiltersSaga from "./modelFilters";
import showroomFiltersSaga from "./showroomFilters";
import documentTypeSaga from "./documentType";
import marketSaga from "./market";
import dealerSaga from "./dealer";

const rootSagas = function* rootSagas() {
  yield all([
    marketSaga(),
    vehicleListSaga(),
    modelFiltersSaga(),
    showroomFiltersSaga(),
    testDriveSaga(),
    showroomSaga(),
    documentTypeSaga(),
    dealerSaga(),
  ]);
};

export default rootSagas;
