import AppRouter from "./routers/Routers";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loadPublicSettings } from "./redux-sagas/actions";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES, TDB_LANGUAGE_KEY } from "./utils/constants";

function App() {
	const dispatch = useDispatch();
	const settings = useSelector((state) => state.settings);
	const { i18n } = useTranslation();

	useEffect(() => {
		if (!settings) {
			const code = new URLSearchParams(window.location.search).get("code");
			if (code) {
				dispatch(loadPublicSettings(code));
			}
		}

		let lang = new URLSearchParams(window.location.search).get("lang");
		if (lang) {
			lang = lang.toLowerCase();
			const isLangSupported = SUPPORTED_LANGUAGES.includes(lang);
			i18n.changeLanguage(isLangSupported ? lang : DEFAULT_LANGUAGE);
			localStorage.setItem(TDB_LANGUAGE_KEY, lang);
		}
	}, [settings, i18n, dispatch]);

	useEffect(() => {
		const app = document.querySelector(".App");

		if (app) {
			const url =
				window.location !== window.parent.location ? document.referrer : document.location.href;

			const postOutputHeight = () => {
				window.parent.postMessage(app.scrollHeight, url);
			};

			window.addEventListener("message", (event) => {
				 if (event && event.origin === window.origin) {
					window.parentOrigin = new URL(url).origin;
					console.log('parent origin', window.parentOrigin);
				 }
			});

			const heightObserver = new ResizeObserver(postOutputHeight);
			heightObserver.observe(app);

			return () => heightObserver.unobserve(app);
		}
	}, []);

	return <div className='App'>{settings && <AppRouter />}</div>;
}

export default App;
