import axios from "axios";
import { TDB_MARKET_KEY } from "../utils/constants";

const BASE_URL = `${window.location.origin}/api/v1`;

const fetchClient = () => {
  const client = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "x-market-id": localStorage.getItem(TDB_MARKET_KEY)
    }
  });

  return client;
};

export default fetchClient;
