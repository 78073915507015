import "./appliedFilter.css";
import { AiOutlineClose } from "react-icons/ai";

const AppliedFilter = ({ children, current, name, removeFilter }) => {
  return (
    <div className="applied-filter">
      <span>{children}</span>
      <AiOutlineClose onClick={() => removeFilter(name, current)} />
    </div>
  );
};

export default AppliedFilter;
