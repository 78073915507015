import { useState } from "react";
import { useSelector } from "react-redux";

const useValidateForm = () => {
	const [errors, setErrors] = useState({});
	const documentTypes = useSelector((state) => state.documentTypes).data;

	const market = new URLSearchParams(window.location.search).get("code").toLowerCase();
	const numberRegex = new RegExp(/^(\s*|\d+)$/);

	const getDocumentType = (documentTypeId) =>
		documentTypes.find((item) => item.id === documentTypeId)?.name;

	// const replaceAt = (str, index, replacement) => {
	// 	return str.substring(0, index) + replacement + str.substring(index + replacement.length);
	// };

	const getDocumentNumberIsValid = (documentTypeId, value) => {
		const documentType = getDocumentType(documentTypeId);
		switch (true) {
			case documentType === "RUT" && market === "chl":
				return (
					value.length <= 10 && value.match(getDocumentNumberRegex(documentTypeId, "onChange"))
				);
			case documentType === "Driver's License" && market === "phl":
				return (
					value.length <= 15 && value.match(getDocumentNumberRegex(documentTypeId, "onChange"))
				);
			default:
				return true;
		}
	};

	const getDocumentNumberNewValue = (value, documentTypeId) => {
		const documentType = getDocumentType(documentTypeId);
		switch (documentType) {
			// case "RUT":
			// 	if (value.length === 9 && !value.includes("-"))
			// 		return replaceAt(replaceAt(value, 8, "-"), 9, value[8]);
			// 	return value;
			// case documentType === "Driver's License" && market === "phl":
			// 	if (value.length === 4 && !value.includes("-")) return replaceAt(value, 3, "-");
			// 	if (value.length === 7 && value[6] !== "-") return replaceAt(value, 6, "-");
			// 	return value;
			default:
				return value;
		}
	};

	const getDocumentNumberRegex = (documentTypeId, type) => {
		const documentType = getDocumentType(documentTypeId);
		const isOnSubmit = type === "onSubmit";

		switch (true) {
			case documentType === "RUT" && market === "chl":
				if (isOnSubmit) return new RegExp(/^[0-9]{7,8}-[0-9Kk]$/);
				return new RegExp(/^[0-9-Kk]+$|^$/);
			case documentType === "Driver's License" && market === "phl":
				// if (isOnSubmit) return new RegExp(/^[A-Z]\d{2}-\d{2}-\d{6}$/);
				// else return new RegExp(/^[A-Za-z0-9-]*$/);
				if (isOnSubmit) return new RegExp(/^[A-Za-z0-9]{5,15}$/);
				return new RegExp(/^[A-Za-z0-9]*$/);
			default:
				return new RegExp(/.*/);
		}
	};

	const getPhoneNumberNewValue = (prevValue, value) => {
		switch (market) {
			case "chl":
				return !prevValue && (value.match(numberRegex) || value === "+")
					? `+56${value === "+" ? "" : value}`
					: value;
			case "per":
				return !prevValue && (value.match(numberRegex) || value === "+")
					? `+51${value === "+" ? "" : value}`
					: value;
			case "phl":
				return !prevValue && (value.match(numberRegex) || value === "+" || value === "0")
					? `+63${value === "+" || value === "0" ? "" : value}`
					: value;
			default:
				return value;
		}
	};

	const getPhoneNumberIsValid = (value, type) => {
		const isOnChange = type === "onChange";
		switch (market) {
			case "chl":
				const chlNumRegex = new RegExp(isOnChange ? /^$|\d*\+\+?5?6?\d{0,9}$/ : /^\+56\d{9}$/);
				return (isOnChange ? value.length <= 12 : value.length === 12) && chlNumRegex.test(value);
			//return (isOnChange ? value.length <= 11 : value.length === 11) && numberRegex.test(value);
			case "per":
				const peruNumRegex = new RegExp(isOnChange ? /^$|\d*\+\+?5?1?\d{0,9}$/ : /^\+51\d{9}$/);
				return (isOnChange ? value.length <= 12 : value.length === 12) && peruNumRegex.test(value);
			case "phl":
				const phlNumRegex = new RegExp(isOnChange ? /^$|\d*\+\+?6?3?\d{0,10}$/ : /^\+63\d{10}$/);
				return (isOnChange ? value.length <= 13 : value.length === 13) && phlNumRegex.test(value);
			default:
				return true;
		}
	};

	const validateOnChange = (field, value, payload) => {
		let newValue;
		const returnObj = {
			newPayload: { ...payload, [field]: value }
		};

		switch (field) {
			case "comments":
				returnObj.isValid = value.length <= 100;
				break;
			case "firstname":
			case "lastname":
				returnObj.isValid = value.length <= 80;
				returnObj.newPayload = { ...payload, [field]: value.replace(/\d+/g, "") };
				break;
			case "documentNumber":
				returnObj.isValid = getDocumentNumberIsValid(payload.documentTypeId, value);
				newValue = getDocumentNumberNewValue(value, payload.documentTypeId);
				returnObj.newPayload = { ...payload, [field]: newValue };
				break;
			case "phoneNumber":
				newValue = getPhoneNumberNewValue(payload.phoneNumber, value);
				returnObj.isValid = getPhoneNumberIsValid(newValue, "onChange");
				returnObj.newPayload = { ...payload, [field]: newValue.replace(" ", "") };
				break;
			default:
				returnObj.isValid = true;
				break;
		}

		return returnObj;
	};

	const validateOnSubmit = (payload, type) => {
		const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
		const err = {};

		if (type !== "manage") {
			if (!payload.firstname) err.firstname = true;
			if (!payload.lastname) err.lastname = true;
			if (!payload.phoneNumber || !getPhoneNumberIsValid(payload.phoneNumber, "onSubmit"))
				err.phoneNumber = true;
			if (type === "inquiry" && !payload.vehicleId) err.vehicleId = true;
			if (type === "inquiry" && !payload.showroomId) err.showroomId = true;
		}
		if (!payload.email || !payload.email.match(emailRegex)) err.email = true;
		if (!payload.documentTypeId) err.documentTypeId = true;
		if (
			!payload.documentNumber ||
			!payload.documentNumber.match(getDocumentNumberRegex(payload.documentTypeId, "onSubmit"))
		)
			err.documentNumber = true;

		setErrors(err);
	};

	const isSubmitEnabled = (checkboxValues, payload, location) => {
		let condition =
			(location !== "manage"
				? checkboxValues.terms &&
				  checkboxValues.data &&
				  payload.firstname &&
				  payload.lastname &&
				  payload.phoneNumber
				: true) &&
			payload.email &&
			payload.documentTypeId &&
			payload.documentNumber;

		if (location === "inquiry") condition = condition && payload.showroomId && payload.vehicleId;

		return !condition;
	};

	const resetErrors = () => setErrors({});

	return { errors, isSubmitEnabled, validateOnChange, validateOnSubmit, resetErrors };
};

export default useValidateForm;
