import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_DOCUMENT_TYPES } from "../actions/types";
import { getDocumentTypesApi } from "../api/handler";
import { setDocumentTypes } from "../actions";

function* handleGetDocumentTypes(args) {
  try {
    const response = yield call(getDocumentTypesApi, args.params);
    if (response.status === 200) {
      yield put(setDocumentTypes(response.data));
    }
  } catch (e) {}
}

export default function* documentTypeSaga() {
  yield all([takeLatest(GET_DOCUMENT_TYPES, handleGetDocumentTypes)]);
}
