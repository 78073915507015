import Button from "../../Button/Button";
import "./vehicleCardSuccess.css";
import { FaLocationArrow } from "react-icons/fa";
import { FaCalendarDays, FaClock } from "react-icons/fa6";
import { BsPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
  setCurrentPage,
  setUserTestDriveInfo
} from "../../../redux-sagas/actions/index";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const VehicleCardSuccess = ({ handleBack, bookingData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleViewTestDrives = () => {
    dispatch(
      setUserTestDriveInfo({
        documentTypeId: bookingData.documentTypeId,
        documentNum: bookingData.documentNumber,
        email: bookingData.email
      })
    );
    dispatch(setCurrentPage("test-drives"));
  };

  return (
    <div className="vehicle-success align-items-md-center">
      <h3 className="text-center text-lg-start">
        {getTranslation("Successful reservation!", t)}
      </h3>
      <div className="reserve-info">
        <div className="col-one">
          <p>{getTranslation("Reservation information", t)}</p>
          <div>
            <FaLocationArrow />
            <span>{bookingData.showroomName}</span>
          </div>
          <div>
            <FaCalendarDays />
            <span>{format(new Date(bookingData.bookingDate), 'dd/MM/yyyy')}</span>
          </div>
          <div>
            <FaClock />
            <span>{bookingData.startTimeFormatted}</span>
          </div>
        </div>
        <div className="col-two">
          <p>{getTranslation("Contact information", t)}</p>
          <div>
            <BsPersonFill />
            <span>{bookingData.firstname + " " + bookingData.lastname}</span>
          </div>
          <div>
            <BsFillTelephoneFill />
            <span>{bookingData.phoneNumber}</span>
          </div>
          <div>
            <IoMail />
            <span>{bookingData.email}</span>
          </div>
        </div>
      </div>
      <div className="buttons align-self-center align-self-lg-end d-flex gap-3">
        <Button
          type="secondary"
          text="View my Test Drive"
          handleClick={handleViewTestDrives}
        />
        <Button type="primary" text="Return" handleClick={handleBack} />
      </div>
    </div>
  );
};

export default VehicleCardSuccess;
