import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../utils/getTranslation";
import "./functionalityCol.css";

const FunctionalityCol = ({ children, text, handleClick, disabled, className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={"func-col " + className + (disabled ? " disabled" : "")}
      onClick={disabled ? undefined : handleClick}
    >
      <span>{children}</span>
      <p>{getTranslation(text, t)}</p>
    </div>
  );
};

export default FunctionalityCol;
