import "./scheduleContent.css";
import MuiDatePicker from "../../../components/DatePicker/MuiDatePicker";
import TooltipButton from "../../../components/TooltipButton/TooltipButton";
import VehicleCard from "../../../components/VehicleCard/VehicleCard";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { formatDate } from "../../../utils/formatDateTime";
import { getDate } from "date-fns";
import orderBy from "../../../db/orderBy";
import getMonthDays from "../../../utils/getMonthDays";
import DateCard from "../../../components/DateCard/DateCard";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import InfoModal from "../../../components/Modal/InfoModal/InfoModal";
import Button from "../../../components/Button/Button";
import OrderByModal from "../../../components/Mobile/OrderByModal/OrderByModal";
import FiltersModal from "../../../components/Mobile/FiltersModal/FiltersModal";
import { getDocumentTypes } from "../../../redux-sagas/actions";
import AppliedFilter from "../../../components/AppliedFilter/AppliedFilter";

const ScheduleContent = ({
  queryParams,
  setQueryParams,
  defaultDate,
  filters,
  setFilters,
  removeFilter,
  setFetchVehicleData
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toReschedule = useSelector((state) => state.toReschedule);
  const vehicleList = useSelector((state) => state.vehicleList);
  const [distribution, setDistribution] = useState(5);
  const [date, setDate] = useState(
    toReschedule
      ? new Date(toReschedule.bookingDate) >= new Date()
        ? new Date(toReschedule.bookingDate)
        : defaultDate
      : defaultDate
  );
  const [orderValue, setOrderValue] = useState("");
  const [index, setIndex] = useState(
    getDate(date) % distribution
      ? Math.floor(getDate(date) / distribution)
      : getDate(date) / distribution - 1
  );
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showMobileModals, setShowMobileModals] = useState({
    orderBy: false,
    filters: false
  });

  const toggleMobileModal = (type) =>
    setShowMobileModals({
      ...showMobileModals,
      [type]: !showMobileModals[type]
    });

  useEffect(() => { loadDocumentTypes() }, [])

  useEffect(() => {
    const handleWindowResize = () =>
      setDistribution(
        Math.floor(window.innerWidth / 80) < 5
          ? Math.floor(window.innerWidth / 80)
          : 5
      );
    window.addEventListener("resize", handleWindowResize);
    setIndex(
      getDate(date) % distribution
        ? Math.floor(getDate(date) / distribution)
        : getDate(date) / distribution - 1
    );
  }, [distribution]);

  const handleChangeDate = (newDate, type) => {
    setDate(newDate);
    setQueryParams({ ...queryParams, date: formatDate(newDate) });
    if (type === "calendar")
      setIndex(
        getDate(newDate) % distribution
          ? Math.floor(getDate(newDate) / distribution)
          : getDate(newDate) / distribution - 1
      );
  };

  const handleShowmore = () => {
    setQueryParams({ ...queryParams, take: queryParams.take + 3 });
    setFetchVehicleData(true);
  }

  const handleChangeOrder = (e) => {
    setOrderValue(e.target.value);
    setQueryParams({ ...queryParams, orderBy: e.target.value });
    setFetchVehicleData(true);
  };

  const handlePreviousSelection = () => setIndex(index - 1);

  const handleNextSelection = () => setIndex(index + 1);

  const toggleInfoModal = () => setShowInfoModal(!showInfoModal);

  const loadDocumentTypes = () => dispatch(getDocumentTypes(""));

  return (
    <div className="schedule">
      <div className="schedule-header">
        <div className="mobile-filters">
          <h3>{getTranslation("Search", t)}</h3>
          <span>
            {getTranslation(
              "Select a filter to see the details of the models available for Test Drive.",
              t
            )}
          </span>
          <div>
            <Button
              handleClick={() => toggleMobileModal("filters")}
              type="primary"
              text="Filters"
            />
            {/* Mobile order by dropdown */}
            <Dropdown
              onChange={handleChangeOrder}
              value={orderValue}
              data={orderBy.map((item) =>
                item.id === "showroomName"
                  ? { ...item, name: getTranslation("Branch", t) }
                  : { ...item }
              )}
              disabled={true}
              handleClick={() => toggleMobileModal("orderBy")}
            />
          </div>
        </div>
        <div className="applied-mobile">
        {filters.models.concat(filters.showrooms).map((item, idx) => {
					return (
						<AppliedFilter
							key={idx}
							name={item.modelName ? "models" : "showrooms"}
							current={item}
							removeFilter={removeFilter}
						>
							{item.modelName ? `${item.modelName} (${item.count})` : item.showroomName}
						</AppliedFilter>
					);
				})}
        </div>
        <p>{getTranslation("Select a date", t)}</p>
        <MuiDatePicker
          defaultDate={defaultDate}
          variant="standard"
          value={date}
          handleChange={handleChangeDate}
        />
        <div className="d-flex flex-sm-column flex-lg-row justify-content-between mt-3">
          <div className="date-selection mb-sm-3 mb-lg-0">
            <span
              className={index ? "" : "inactive"}
              onClick={index ? handlePreviousSelection : undefined}
            >
              <FaArrowCircleLeft />
            </span>
            {getMonthDays(date, distribution)[index]?.map((item) => (
              <DateCard
                handleChangeDate={handleChangeDate}
                defaultDate={defaultDate}
                currentDate={date}
                key={item.date}
                date={item}
              />
            ))}
            <span
              className={
                index === getMonthDays(date, distribution).length - 1
                  ? "inactive"
                  : ""
              }
              onClick={
                index === getMonthDays(date, distribution).length - 1
                  ? undefined
                  : handleNextSelection
              }
            >
              <FaArrowCircleRight />
            </span>
          </div>
          <TooltipButton
            toggleInfoModal={toggleInfoModal}
            text={getTranslation("I want to be contacted", t)}
          />
        </div>
      </div>
      <div className="schedule-header-wrapper d-flex justify-content-between align-items-center">
        <span className="lb-2 desktop-results">
          {getTranslation("Search results", t)}
        </span>
        <span className="lb-2 mobile-results">
          {getTranslation("Model Information", t)}
        </span>
        {/* <OrderByFilter /> */}
        <Dropdown
          onChange={handleChangeOrder}
          value={orderValue}
          data={orderBy}
        />
      </div>
      <div className="schedule-vehicle-wrapper">
        {vehicleList.data !== undefined && !vehicleList.data.length
          ? <p className="no-result">{getTranslation("This demo is not available in this showroom.", t)}</p>
          : vehicleList.data?.map((vehicle, idx) => {
            return (
              <VehicleCard
                date={date}
                handleOpenDropdown={loadDocumentTypes}
                key={idx}
                item={vehicle}
                idx={idx}
                queryParams={queryParams}
              />
            );
          })}
        {queryParams.take < vehicleList.totalRecords && (
          <button onClick={handleShowmore} className="load-more-btn">
            {getTranslation("Show more", t)}...
          </button>
        )}
        {/* <span className="lb-2">Otros vehículos recomendados</span>
        {vehicleList.slice(0, 1).map((item, idx) => {
          return <VehicleCard key={idx} idx={idx} item={item} />;
        })} */}
      </div>
      {<InfoModal toggleModal={toggleInfoModal} open={showInfoModal} />}
      {
        <OrderByModal
          toggleModal={toggleMobileModal}
          open={showMobileModals.orderBy}
          handleChangeOrder={handleChangeOrder}
        />
      }
      {
        <FiltersModal
          open={showMobileModals.filters}
          toggleModal={toggleMobileModal}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          filters={filters}
          setFilters={setFilters}
          setFetchVehicleData={setFetchVehicleData}
        />
      }
    </div>
  );
};

export default ScheduleContent;
