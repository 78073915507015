import React, { useEffect } from "react";
import "./manageTestDriveForm.css";
import Button from "../../../components/Button/Button";
import MuiInput from "../../../components/MuiInput/MuiInput";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentTypes } from "../../../redux-sagas/actions";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import getDocumentNumberLabel from "../../../utils/getDocumentNumberLabel";

const ManageTestDriveForm = ({
  payload,
  setPayload,
  isSubmitted,
  fetchTestDriveData,
  errors,
  validateOnChange,
  validateOnSubmit,
  isSubmitEnabled
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Use Selectors
  const documentTypes = useSelector((state) => state.documentTypes);

  useEffect(() => {
    if (documentTypes.data.length && !payload?.documentTypeId && documentTypes.data.length === 1)
      setPayload((prevPayload) => ({ ...prevPayload, documentTypeId: documentTypes.data[0].id }));
  }, [documentTypes.data]);

  useEffect(() => {
    if (isSubmitted.current && !Object.keys(errors).length)
      fetchTestDriveData();
  }, [errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const obj = validateOnChange(name, value, payload);
    if (obj.isValid) setPayload(obj.newPayload);

    if (name === "documentTypeId") setPayload((prevPayload) => ({ ...prevPayload, documentNumber: "" }))
  };

  const handleOpen = () => {
    dispatch(getDocumentTypes(""));
  };

  const handleClick = (e) => {
    e.preventDefault();
    isSubmitted.current = true;

    // Validate required fields
    validateOnSubmit(payload, "manage");
  };

  return (
    <div className="manage-test-drive-form m-3 m-lg-0">
      <h4 className="col-lg col-12 p-0 pe-lg-4 text-center text-lg-start">
        {getTranslation("Enter your data", t)}
      </h4>
      <div className="manage-test-drive-form-inputs row m-0">
        <div className="col-lg col-md-6 col-12 p-0 pe-lg-4 pe-md-4 mb-4 mb-lg-0">
          <MuiInput
            label={`${getTranslation("Document Type", t)}*`}
            type="dropdown"
            name="documentTypeId"
            handleChange={handleChange}
            handleOpen={handleOpen}
            data={documentTypes.data.map((item) => ({
              ...item,
              name: getTranslation(item.name, t)
            }))}
            textField="name"
            valueField="id"
            value={payload.documentTypeId}
            error={errors.documentTypeId}
            placeholder="Placeholder aquí"
          />
        </div>
        <div className="col-lg col-md-6 col-12 p-0 ps-lg-4 pe-lg-4 ps-md-4 mb-4 mb-lg-0">
          <MuiInput
            label={`${getDocumentNumberLabel(payload.documentTypeId)}*`}
            type="text"
            placeholder="Placeholder aquí"
            handleChange={handleChange}
            name="documentNumber"
            value={payload.documentNumber}
            error={errors.documentNumber}
          />
        </div>
        <div className="col-lg col-md-6 col-12 p-0 ps-lg-4 pe-lg-4 pe-md-4 mb-4 mb-lg-0">
          <MuiInput
            label={`${getTranslation("Email", t)}*`}
            type="text"
            placeholder="Placeholder aquí"
            handleChange={handleChange}
            name="email"
            value={payload.email}
            error={errors.email}
          />
        </div>
        <div className="col-lg-2 col-md-6 col-12 p-0 ps-lg-4 ps-md-4 mb-0 mb-lg-0">
          <Button
            className="w-100"
            type="primary"
            text={t("search_test_drive")}
            disabled={isSubmitEnabled(null, payload, "manage")}
            handleClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageTestDriveForm;
