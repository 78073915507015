import { Checkbox } from "@mui/material";
import Button from "../../Button/Button";
import "./vehicleCardForms.css";
import { useEffect, useRef, useState } from "react";
import DisclaimerModal from "../../Modal/DisclaimerModal/DisclaimerModal";
import MuiInput from "../../MuiInput/MuiInput";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useValidateForm from "../../../hooks/useValidateForm";
import getDocumentNumberLabel from "../../../utils/getDocumentNumberLabel";

const VehicleCardForms = ({
  handleBack,
  payload,
  errors,
  handleChange,
  handleSubmit,
  handleOpenDropdown,
  bookingError
}) => {
  const { t } = useTranslation();
  const mouseYPos = useRef();
  const disclaimerType = useRef();
  const documentTypes = useSelector((state) => state.documentTypes);
  const [showModal, setShowModal] = useState(false);
  const [disclaimerComponent, setDisclaimerComponent] = useState(null);
  const [value, setValue] = useState({
    terms: false,
    data: false
  });
  const isInsideIframe = window.location !== window.parent.location;

  const { isSubmitEnabled } = useValidateForm();

  const toggleModal = (e, type) => {
    setShowModal(!showModal);
    disclaimerType.current = type;
    if (isInsideIframe) {
      const bounds = document.querySelector(".App").getBoundingClientRect();
      mouseYPos.current = e.clientY - bounds.top;
    }
  };
  const handleCheckboxChange = (e) => setValue({ ...value, [e.target.name]: e.target.checked });

  const settings = useSelector((state) => state.settings);
  const brandSettings = settings?.brandGroupConfiguration?.brandConfiguration;
  const isTermsAndConditionsUrl = brandSettings && brandSettings.termsAndConditionsUrl;
  const isTermsAndConditionsText = brandSettings && brandSettings.termsAndConditionsText;
  const isDataPolicyUrl = brandSettings && brandSettings.dataProtectionPolicyUrl;
  const isDataPolicyText = brandSettings && brandSettings.dataProtectionPolicyText;

  useEffect(() => {
    if (isInsideIframe && showModal && disclaimerComponent) {
      const modalBackdropHeight = disclaimerComponent.scrollHeight;
      const modalHeight = document.querySelector(".MuiPaper-root").offsetHeight;

      // check if modal exceeds from view
      if (modalBackdropHeight - mouseYPos.current < modalHeight) {
        disclaimerComponent.style.top = mouseYPos.current - (modalHeight + 250) + "px";
        disclaimerComponent.style.bottom = 0;
      } else {
        disclaimerComponent.style.bottom = "auto";
        disclaimerComponent.style.top = mouseYPos.current - 300 + "px";
      }
    }
  }, [disclaimerComponent, showModal, isInsideIframe]);

  return (
    <div className='vehicle-forms'>
      <h3>{getTranslation("Contact Information", t)}</h3>
      <div className='inputs'>
        <MuiInput
          handleChange={handleChange}
          name='firstname'
          type='text'
          label={`${getTranslation("Name", t)}*`}
          value={payload.firstname}
          error={errors.firstname}
        />
        <MuiInput
          handleChange={handleChange}
          name='lastname'
          type='text'
          label={`${getTranslation("Surname", t)}*`}
          value={payload.lastname}
          error={errors.lastname}
        />
        <MuiInput
          type='dropdown'
          name='documentTypeId'
          data={documentTypes.data.map((item) => ({
            ...item,
            name: getTranslation(item.name, t)
          }))}
          textField='name'
          valueField='id'
          handleOpen={handleOpenDropdown}
          handleChange={handleChange}
          label={`${getTranslation("Document type", t)}*`}
          value={payload.documentTypeId}
          error={errors.documentTypeId}
        />
        <MuiInput
          handleChange={handleChange}
          name='documentNumber'
          type='text'
          label={`${getDocumentNumberLabel(payload.documentTypeId)}*`}
          value={payload.documentNumber}
          error={errors.documentNumber}
        />
        <MuiInput
          handleChange={handleChange}
          name='email'
          type='text'
          label='Email*'
          value={payload.email}
          error={errors.email}
        />
        <MuiInput
          handleChange={handleChange}
          name='phoneNumber'
          type='text'
          label={`${getTranslation("Phone Number", t)}*`}
          value={payload.phoneNumber}
          error={errors.phoneNumber}
        />
      </div>
      <div className='terms'>
        <p className='heading'>{getTranslation("Terms and conditions", t)}:</p>
        <span className='align-items-center'>
          <Checkbox
            onChange={handleCheckboxChange}
            name='terms'
            checked={value.terms}
            disableRipple
          />
          <label>
            {getTranslation("Accept", t)}{" "}
            {isTermsAndConditionsUrl ? (
              <a
                href={brandSettings.termsAndConditionsUrl}
                target={"_blank"}
                className='terms-highlighted'
                rel='noreferrer'
              >
                {getTranslation("Terms and conditions", t)}
              </a>
            ) : (
              <p
                className='terms-highlighted'
                onClick={(e) => toggleModal(e, "terms")}
              >
                {getTranslation("Terms and conditions", t)}
              </p>
            )}
          </label>
        </span>
        <span className='align-items-center'>
          <Checkbox
            onChange={handleCheckboxChange}
            name='data'
            checked={value.data}
            disableRipple
          />
          <label>
            {getTranslation("I declare that I am aware of the", t)}{" "}
            {isDataPolicyUrl ? (
              <a
                href={brandSettings.dataProtectionPolicyuRL}
                target={"_blank"}
                className='terms-highlighted'
                rel='noreferrer'
              >
                {getTranslation("Personal Data Protection Policy", t)}
              </a>
            ) : (
              <p
                className='terms-highlighted'
                onClick={(e) => toggleModal(e, "data-policy")}
              >
                {getTranslation("Personal Data Protection Policy", t)}
              </p>
            )}{" "}
            {getTranslation("and authorize the processing of my data.", t)}
          </label>
        </span>
      </div>
      <div className='btn-group'>
        <Button
          handleClick={handleBack}
          type='secondary'
          text='Return'
        />
        <Button
          disabled={isSubmitEnabled(value, payload, "booking")}
          handleClick={handleSubmit}
          type='primary'
          text='Schedule'
        />
      </div>
      <DisclaimerModal
        setDisclaimerComponent={setDisclaimerComponent}
        disclaimerType={disclaimerType.current}
        textContent={
          disclaimerType.current === "terms"
            ? isTermsAndConditionsText
              ? brandSettings.termsAndConditionsText
              : ""
            : isDataPolicyText
              ? brandSettings.dataProtectionPolicyText
              : ""
        }
        toggleModal={toggleModal}
        open={showModal}
      />
      {bookingError && <span className='text-center error-message'>{bookingError}</span>}
    </div>
  );
};

export default VehicleCardForms;
