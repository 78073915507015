import { format } from "date-fns";

export const formatDate = (date) => format(new Date(date), "yyyy-MM-dd");

export const formatCurrentDate = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};
