import React, { useEffect, useRef, useState } from "react";
import "./inquiryModal.css";
import { Checkbox, Dialog } from "@mui/material";
import Button from "../../Button/Button";
import { MdClose } from "react-icons/md";
import MuiInput from "../../MuiInput/MuiInput";
import DisclaimerModal from "../DisclaimerModal/DisclaimerModal";
import { useDispatch, useSelector } from "react-redux";
import { getDealerList, getDocumentTypes, requestTestDrive } from "../../../redux-sagas/actions";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import addToDataLayer from "../../../utils/addToDataLayer";
import fetchClient from "../../../services/fetch";
import useValidateForm from "../../../hooks/useValidateForm";
import getDocumentNumberLabel from "../../../utils/getDocumentNumberLabel";
import getBrands from "../../../utils/getBrands";
import getUtmParams from "../../../utils/getUtmParams";

const InquiryModal = ({ open, toggleModal, toggleInfoModal, setModalComponent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disclaimerType = useRef();
  const mouseYPos = useRef();
  const dealerList = useSelector((state) => state.dealerList);
  const documentTypes = useSelector((state) => state.documentTypes);
  const settings = useSelector((state) => state.settings);
  const brandSettings = settings?.brandGroupConfiguration?.brandConfiguration;
  const brands = getBrands();
  const isTermsAndConditionsUrl = brandSettings && brandSettings.termsAndConditionsUrl;
  const isTermsAndConditionsText = brandSettings && brandSettings.termsAndConditionsText;
  const isDataPolicyUrl = brandSettings && brandSettings.dataProtectionPolicyUrl;
  const isDataPolicyText = brandSettings && brandSettings.dataProtectionPolicyText;
  const isInsideIframe = window.location !== window.parent.location;

  const { errors, isSubmitEnabled, validateOnChange, validateOnSubmit } = useValidateForm();
  const [payload, setPayload] = useState({});
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [disclaimerComponent, setDisclaimerComponent] = useState(null);
  const [vehicleList, setVehicleList] = useState({
    data: [],
    value: ""
  });
  const [checkboxValues, setCheckboxValues] = useState({
    terms: false,
    data: false
  });
  const isSubmit = useRef(false);

  useEffect(() => {
    dispatch(getDocumentTypes(""));
  }, []);

  useEffect(() => {
    if (documentTypes.data.length && !payload?.documentTypeId && documentTypes.data.length === 1)
      setPayload({ ...payload, documentTypeId: documentTypes.data[0].id });
  }, [documentTypes.data]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const obj = validateOnChange(name, value, payload);
    if (obj.isValid) setPayload(obj.newPayload);

    if (name === "documentTypeId")
      setPayload((prevPayload) => ({ ...prevPayload, documentNumber: "" }));
    if (name === "vehicleId")
      setVehicleList({ ...vehicleList, value: vehicleList.data.find((v) => v.id === value) });
  };

  const getVehicleList = () =>
    fetchClient()
      .get("/commondata/vehicles-interest?brands=" + brands)
      .then((res) => setVehicleList({ ...vehicleList, data: res.data.data }));

  const handleOpen = (type) => {
    switch (type) {
      case "showroom":
        dispatch(getDealerList("?brands=" + brands));
        break;
      case "document-type":
        dispatch(getDocumentTypes(""));
        break;
      case "vehicles":
        getVehicleList();
        break;
      default:
        break;
    }
  };

  const toggleTermsModal = (e, type) => {
    setShowTermsModal(!showTermsModal);
    disclaimerType.current = type;
    if (isInsideIframe) mouseYPos.current = e.clientY;
  };

  const handleChangeCheckbox = (e) =>
    setCheckboxValues({ ...checkboxValues, [e.target.name]: e.target.checked });

  const handleAccept = (e) => {
    e.preventDefault();
    validateOnSubmit(payload, "inquiry");
    isSubmit.current = true;
  };

  const finishSubmit = () => {
    toggleInfoModal();
    toggleModal(false);

    addToDataLayer(brandSettings, {
      ...payload,
      modelName: vehicleList.value.modelName,
      variantName: vehicleList.value.variantName,
      showroomName: dealerList.data.find((showroom) => showroom.id === payload.showroomId).name,
      dateFormatted: null,
      timeFormatted: null
    });

    dispatch(requestTestDrive({ ...payload, brands, ...getUtmParams().payload }));
  };

  useEffect(() => {
    if (isSubmit.current && !Object.keys(errors).length) {
      finishSubmit();
    }
  }, [errors]);

  useEffect(() => {
    if (isInsideIframe && showTermsModal && disclaimerComponent) {
      disclaimerComponent.style.bottom = "auto";
      disclaimerComponent.style.top = mouseYPos.current - 750 + "px";
    }
  }, [disclaimerComponent, showTermsModal, isInsideIframe]);

  return (
    <div>
      <Dialog
        className='inquiry'
        aria-labelledby='inquiry-modal'
        open={open}
        ref={(node) => setModalComponent(node)}
      >
        <div
          className='align-self-end'
          onClick={toggleModal}
        >
          <MdClose className='close-icon' />
        </div>
        <h2 className='align-self-center'>{getTranslation("Request Test Drive", t)}</h2>
        <div className='inquiry-forms'>
          <MuiInput
            name='firstname'
            label={`*${getTranslation("Name", t)}`}
            type='text'
            handleChange={handleChange}
            value={payload.firstname}
            error={errors.firstname}
          />
          <MuiInput
            name='lastname'
            label={`*${getTranslation("Surname", t)}`}
            type='text'
            handleChange={handleChange}
            value={payload.lastname}
            error={errors.lastname}
          />
          <MuiInput
            name='email'
            label='*Email'
            type='text'
            handleChange={handleChange}
            value={payload.email}
            error={errors.email}
          />
          <MuiInput
            name='phoneNumber'
            label={`*${getTranslation("Phone Number", t)}`}
            type='text'
            handleChange={handleChange}
            value={payload.phoneNumber}
            error={errors.phoneNumber}
          />
          <MuiInput
            name='vehicleId'
            label={`*${getTranslation("Model of interest", t)}`}
            type='dropdown'
            data={vehicleList.data}
            textField='name'
            valueField='id'
            handleOpen={() => handleOpen("vehicles")}
            handleChange={handleChange}
            value={payload.vehicleId}
            error={errors.vehicleId}
          />
          <MuiInput
            name='documentTypeId'
            label={`*${getTranslation("Document Type", t)}`}
            type='dropdown'
            data={documentTypes.data}
            textField='name'
            valueField='id'
            handleOpen={() => handleOpen("document-type")}
            handleChange={handleChange}
            value={payload.documentTypeId}
            error={errors.documentTypeId}
          />
          <MuiInput
            name='documentNumber'
            label={`*${getDocumentNumberLabel(payload.documentTypeId)}`}
            type='text'
            handleChange={handleChange}
            value={payload.documentNumber}
            error={errors.documentNumber}
          />
          <MuiInput
            name='showroomId'
            label={`*${getTranslation("Preferred dealer", t)}`}
            type='dropdown'
            data={dealerList.data}
            textField='name'
            valueField='id'
            handleOpen={() => handleOpen("showroom")}
            handleChange={handleChange}
            value={payload.showroomId}
            error={errors.showroomId}
          />
          <MuiInput
            name='comments'
            label={getTranslation("Comments", t)}
            type='textArea'
            handleChange={handleChange}
            rows={2}
            defaultValue=''
            value={payload.comments}
          />
          <div className='terms'>
            <p className='heading'>{getTranslation("Terms and conditions", t)}:</p>
            <div className='d-flex gap-2 align-items-center mb-3'>
              <Checkbox
                onChange={handleChangeCheckbox}
                name='terms'
                value={checkboxValues.terms}
                disableRipple
              />
              <span>
                {getTranslation("I accept the", t)}{" "}
                {isTermsAndConditionsUrl ? (
                  <a
                    href={brandSettings.termsAndConditionsUrl}
                    target={"_blank"}
                    className='terms-highlighted'
                    rel='noreferrer'
                  >
                    {getTranslation("Terms and conditions", t)}
                  </a>
                ) : (
                  <span
                    className='terms-highlighted'
                    onClick={(e) => toggleTermsModal(e, "terms")}
                  >
                    {getTranslation("Terms and conditions", t)}
                  </span>
                )}
              </span>
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <Checkbox
                onChange={handleChangeCheckbox}
                name='data'
                value={checkboxValues.data}
                disableRipple
              />
              <span>
                <label>
                  {getTranslation("I declare that I am aware of the", t)}{" "}
                  {isDataPolicyUrl ? (
                    <a
                      href={brandSettings.dataProtectionPolicyuRL}
                      target={"_blank"}
                      className='terms-highlighted'
                      rel='noreferrer'
                    >
                      {getTranslation("Personal Data Protection Policy", t)}
                    </a>
                  ) : (
                    <span
                      className='terms-highlighted'
                      onClick={(e) => toggleTermsModal(e, "data-policy")}
                    >
                      {getTranslation("Personal Data Protection Policy", t)}
                    </span>
                  )}{" "}
                  {getTranslation("and authorize the processing of my data.", t)}
                </label>
              </span>
            </div>
          </div>
          <Button
            disabled={isSubmitEnabled(checkboxValues, payload, "inquiry")}
            handleClick={handleAccept}
            text='Accept'
            type='primary'
          />
        </div>
      </Dialog>
      <DisclaimerModal
        disclaimerType={disclaimerType.current}
        setDisclaimerComponent={setDisclaimerComponent}
        toggleModal={toggleTermsModal}
        open={showTermsModal}
        textContent={
          disclaimerType.current === "terms"
            ? isTermsAndConditionsText
              ? brandSettings.termsAndConditionsText
              : ""
            : isDataPolicyText
              ? brandSettings.dataProtectionPolicyText
              : ""
        }
      />
    </div>
  );
};

export default InquiryModal;
