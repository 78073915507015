import { useSelector } from "react-redux";
import HourButton from "../../HourButton/HourButton";
import "./vehicleCardLanding.css";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const VehicleCardLanding = ({ item, toggleHour }) => {
  const { t } = useTranslation();
  const toReschedule = useSelector((state) => state.toReschedule);

  return (
    <div className="vehicle-info d-flex justify-content-between align-items-center">
      <div className="car-info-box">
        <div className="car">
          <img
            src={item?.vehicleImageUrl}
            alt={`${item?.vehicleName} display`}
          />
        </div>
        <div className="d-flex gap-2 justify-content-center">
          <span className="card-label-title">
            {getTranslation("Vehicle", t)}:
          </span>
          <span className="card-label">{item?.vehicleName}</span>
        </div>
      </div>
      <div className="location-time-box">
        <div>
          <span className="card-label-title">
            {getTranslation("Branch", t)}:
          </span>
          <span className="card-label"> {item?.showroomName}</span>
        </div>
        <div className="time-picker d-flex align-items-start">
          <span className="card-label-title">{getTranslation("Hour", t)}:</span>
          <div className="time-box">
            {item?.timeslots.map((timeslot, idx) => (
              <HourButton
                key={idx}
                scheduled={
                  toReschedule &&
                  item.vehicleId === toReschedule.vehicleId &&
                  timeslot.startTimeFormatted ===
                  toReschedule.startTimeFormatted
                }
                value={timeslot.startTimeFormatted}
                onClick={() => toggleHour(timeslot)}
                disabled={!timeslot.isAvailable}
              />
            ))}
          </div>
        </div>
      </div>
      <div>
        <iframe
          src={item?.locationUrl}
          title={`${item?.showroomName} google map location`}
        />
      </div>
    </div>
  );
};

export default VehicleCardLanding;
