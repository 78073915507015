import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_PUBLIC_SETTINGS } from "../actions/types";
import { getPublicSettingsApi } from "../api/handler";
import { setLoading, setPublicSettings } from "../actions";
import { TDB_MARKET_KEY } from "../../utils/constants";
import changeCssVariables from "../../utils/changeCssVariables";
import getBrands from "../../utils/getBrands";

function* handleGetPublicSettings(args) {
	yield put(setLoading(true));
	try {
		const brands = getBrands();
		const settingsResponse = yield call(
			getPublicSettingsApi,
			`?code=${args.code}&brands=${brands}`
		);
		if (settingsResponse.status === 200) {
			const result = settingsResponse.data.data;
			yield put(setPublicSettings(result));
			localStorage.setItem(TDB_MARKET_KEY, result.marketId);

			if (result.brandGroupConfiguration?.brandConfiguration?.styles)
				changeCssVariables(result.brandGroupConfiguration.brandConfiguration.styles);
		}
	} catch (e) {
		console.error(e);
	}

	yield put(setLoading(false));
}

export default function* marketSaga() {
	yield all([takeLatest(GET_PUBLIC_SETTINGS, handleGetPublicSettings)]);
}
