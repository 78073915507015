import { getDaysInMonth, format, getMonth, getYear } from "date-fns";

const getMonthDays = (date, distribution) => {
  const month = getMonth(date) + 1;
  const year = getYear(date);
  const days = getDaysInMonth(date);

  const arr = [];

  let tempArr = [];
  for (let i = 1; i <= days; i++) {
    const tempDate = new Date(`${year}/${month}/${i}`);
    const dayOfWeek = format(tempDate, "EEE");
    tempArr.push({
      value: tempDate,
      date: i,
      dayOfWeek
    });

    if (!(i % distribution) || i === days) {
      arr.push(tempArr);
      tempArr = [];
    }
  }

  return arr;
};

export default getMonthDays;
