import { Dialog } from "@mui/material";
import Button from "../../Button/Button";
import "./disclaimerModal.css";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const DisclaimerModal = ({ open, toggleModal, disclaimerType, textContent, setDisclaimerComponent }) => {
  const { t } = useTranslation();
  const isInsideIframe = window.location !== window.parent.location;
  return (
    <Dialog
      className={`disclaimer ${isInsideIframe ? "in-iframe" : ""}`}
      aria-labelledby="disclaimer-modal"
      open={open}
      ref={(node) => setDisclaimerComponent(node)}
    >
      <h2>
        {disclaimerType === "terms"
          ? getTranslation("Terms and conditions", t)
          : getTranslation("Personal Data Protection Policy", t)}
      </h2>
      <span dangerouslySetInnerHTML={{ __html: textContent }}>
      </span>
      <Button
        handleClick={(e) => toggleModal(e, disclaimerType)}
        text="Accept"
        type="primary"
      />
    </Dialog>
  );
};

export default DisclaimerModal;
