import React from "react";
import "./dropdown.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const Dropdown = ({
  value,
  label,
  onChange,
  data,
  disabled = false,
  handleClick
}) => {
  const { t } = useTranslation();

  return (
    <>
      {/* TODO ITEMS FOR DYNAMIC USE*/}
      <label>{label}</label>
      <Box className="order-dropdown" sx={{ width: 202 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {getTranslation("Order by", t)}
          </InputLabel>
          <Select
            MenuProps={{
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onClick={handleClick}
            value={value || ""}
            variant="standard"
            label={getTranslation("Order by", t)}
            onChange={onChange}
            disabled={disabled}
          >
            {data?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {getTranslation(item.name, t)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default Dropdown;
