import { Dialog } 
from "@mui/material";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import "./filtersModal.css";
import Button from "../../Button/Button";
import {
  getModelFilters,
  getShowroomFilters
} from "../../../redux-sagas/actions";
import "../../Multiselect/multiselect.css"
import MultiselectCustom from "../../MultiselectCustom/MultiselectCustom";
import { useEffect, useState } from "react";

const FiltersModal = ({ open, toggleModal, queryParams, setQueryParams, filters, setFilters, setFetchVehicleData }) => {
  const dispatch = useDispatch();
  const models = useSelector((state) => state.modelFilters);
  const showrooms = useSelector((state) => state.showroomFilters);
  const [selectedFilters, setSelectedFilters] = useState(filters);
  const { t } = useTranslation();
  const closeModal = () => toggleModal("filters");
  const handleOpenModal = (type) => {
    const { brands } = queryParams;

    dispatch(
      type === "showrooms"
        ? getShowroomFilters("?skip=0&take=&brands=" + brands)
        : getModelFilters("?skip=0&take=&brands=" + brands)
    );
  };

  useEffect(() => {
    setSelectedFilters(filters);
  }, [filters])

  const handleChange = (type, current) =>{
    const isSame = (item) =>
			(item.modelName ?? item.showroomName) === (current.modelName ?? current.showroomName);

    if (selectedFilters[type].find((item) => isSame(item))) {
      setSelectedFilters({
				...selectedFilters,
				[type]: selectedFilters[type].filter((item) => !isSame(item))
			});
		} else {
      setSelectedFilters({
				...selectedFilters,
				[type]: selectedFilters[type].concat(current)
			});
		}

    setFetchVehicleData(false);
  }

  const handleAccept = () => {
    const modelIds = selectedFilters.models.map((item) => item.category ? `${item.category}|${item.modelId}` : item.modelId);
		const showroomIds = selectedFilters.showrooms.map((item) => item.showroomId);
		setQueryParams({
			...queryParams,
			models: modelIds.length > 0 ? modelIds.join(",") : "",
			showrooms: showroomIds.length > 0 ? showroomIds.join(",") : ""
		});
    
    setFilters({
      ...filters,
      models: selectedFilters.models,
      showrooms: selectedFilters.showrooms
    });

    closeModal();
    setFetchVehicleData(true);
  };

  const handleCancel = () => {
    setSelectedFilters(filters);
    closeModal();
    setFetchVehicleData(false);
  }

  return (
    <Dialog className="filters-modal" open={open}>
      <div className="modal-header">
        <span>{getTranslation("Filters", t)}</span>
        <span onClick={handleCancel}>
          <MdClose />
        </span>
      </div>
      <div className="modal-types">
        {
          <MultiselectCustom
            data={showrooms.data}
            textField={"showroomName"}
            value={selectedFilters.showrooms}
            label={"Branch"}
            name={"showrooms"}
            onOpen={handleOpenModal}
            handleChange={handleChange}
            showCount={false}
          />
        }
        {
          <MultiselectCustom
            data={models.data}
            textField={"modelName"}
            value={selectedFilters.models}
            label={"Models"}
            name={"models"}
            onOpen={handleOpenModal}
            handleChange={handleChange}
            showCount={true}
          />
        }
      </div>
      <div className="btn-group">
        <Button handleClick={handleCancel} text="Cancel" type="secondary" />
        <Button handleClick={handleAccept} text="Accept" type="primary" />
      </div>
    </Dialog>
  );
};

export default FiltersModal;
