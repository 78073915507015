import { useTranslation } from "react-i18next";
import BookingTable from "../../../components/BookingTable/BookingTable";
import Button from "../../../components/Button/Button";
import {
  setCurrentPage,
  setUserTestDriveInfo
} from "../../../redux-sagas/actions";
import { getTranslation } from "../../../utils/getTranslation";
import "./manageTestDriveBookings.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import BookingMobile from "../../../components/BookingTable/BookingMobile";

const ManageTestDriveBookings = ({ errors, payload, isSubmitted }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);
  // Use Selectors
  const testDrives = useSelector((state) => state.testDrives);
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
  }, [currentWidth]);

  const handleBack = () => {
    dispatch(setCurrentPage("schedule"));
    dispatch(setUserTestDriveInfo({}));
  };

  return (
    <div className="bookings-cont m-3 m-lg-0">
      <h3>{getTranslation("My Test Drives", t)}</h3>
      {testDrives.data.length > 0 ? (
        <div className="bookings">
          {currentWidth >= 576
            ? testDrives.data.map((booking) => (
              <BookingTable
                key={booking.bookingId}
                booking={booking}
                payload={payload}
              />
            ))
            : testDrives.data.map((booking) => (
              <BookingMobile
                key={booking.bookingId}
                booking={booking}
                payload={payload}
              />
            ))}
        </div>
      ) : (
        isSubmitted.current && !Object.keys(errors).length &&
        !isLoading && (
          <p className="no-booking">
            {getTranslation(
              "The user does not have a Test Drive assigned to them at the moment.",
              t
            )}
          </p>
        )
      )}
      {testDrives.data.length > 0 && (
        <Button
          handleClick={handleBack}
          type="primary"
          text={getTranslation("Exit", t)}
        />
      )}
    </div>
  );
};

export default ManageTestDriveBookings;
