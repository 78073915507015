import React from "react";
import "./infoModal.css";
import { Dialog } from "@mui/material";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../utils/getTranslation";

const InfoModal = ({ open, toggleModal }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      className="info-modal"
      aria-labelledby="information-modal"
      open={open}
    >
      <h2>{getTranslation("Request Test Drive", t)}</h2>
      <span>
        {getTranslation(
          "Soon one of our advisors will contact you to provide you with more information about the Test Drive",
          t
        )}
      </span>
      <Button handleClick={toggleModal} text="Return" type="primary" />
    </Dialog>
  );
};

export default InfoModal;
