const changeCssVariables = (response) => {
	const {
		primaryColor,
		secondaryColor,
		hoverPrimaryColor,
		headerTextColor,
		tooltipColor,
		tooltipTextColor,
		notificationErrorColor,
		notificationSuccessColor,
		grayColor,
		disablePrimaryColor,
		disableSecondaryColor,
		bookingSuccessTextColor,
		fontFamily,
		fontFamilyBold,
		headingFontFamily,
		fontFamilyLight,
		primaryButtonColor,
		primaryButtonBackgroundColor,
		primaryButtonBorderColor,
		primaryButtonHoverColor,
		primaryButtonHoverBackgroundColor,
		primaryButtonBorderRadius,
		secondaryButtonColor,
		secondaryButtonBackgroundColor,
		secondaryButtonBorderColor,
		secondaryButtonHoverColor,
		secondaryButtonHoverBackgroundColor,
		secondaryButtonBorderRadius,
		tooltipButtonColor,
		tooltipButtonBackgroundColor,
		tooltipButtonBorderColor,
		tooltipButtonBorderRadius,
		tooltipButtonHoverColor,
		tooltipButtonHoverBackgroundColor,
		tooltipButtonHoverBorderColor
	} = response;
	const body = document.querySelector("body");

	const cssVars = [
		{
			variable: "--primary",
			value: primaryColor
		},
		{
			variable: "--secondary",
			value: secondaryColor
		},
		{
			variable: "--primary-hover",
			value: hoverPrimaryColor
		},
		{
			variable: "--heading",
			value: headerTextColor
		},
		{
			variable: "--tooltip-background-color",
			value: tooltipColor
		},
		{
			variable: "--tooltip-text-color",
			value: tooltipTextColor
		},
		{
			variable: "--notif-success",
			value: notificationSuccessColor
		},
		{
			variable: "--notif-error",
			value: notificationErrorColor
		},
		{
			variable: "--main-grey",
			value: grayColor
		},
		{
			variable: "--disable-primary",
			value: disablePrimaryColor
		},
		{
			variable: "--disable-secondary",
			value: disableSecondaryColor
		},
		{
			variable: "--booking-success",
			value: bookingSuccessTextColor
		},
		{
			variable: "--font-normal",
			value: fontFamily
		},
		{
			variable: "--font-light",
			value: fontFamilyLight
		},
		{
			variable: "--font-bold",
			value: fontFamilyBold
		},
		{
			variable: "--font-heading",
			value: headingFontFamily
		},
		{
			variable: "--primary-button-color",
			value: primaryButtonColor
		},
		{
			variable: "--primary-button-background-color",
			value: primaryButtonBackgroundColor
		},
		{
			variable: "--primary-button-border-color",
			value: primaryButtonBorderColor
		},
		{
			variable: "--primary-button-hover-color",
			value: primaryButtonHoverColor
		},
		{
			variable: "--primary-button-hover-background-color",
			value: primaryButtonHoverBackgroundColor
		},
		{
			variable: "--primary-button-border-radius",
			value: primaryButtonBorderRadius
		},

		{
			variable: "--secondary-button-color",
			value: secondaryButtonColor
		},
		{
			variable: "--secondary-button-background-color",
			value: secondaryButtonBackgroundColor
		},
		{
			variable: "--secondary-button-border-color",
			value: secondaryButtonBorderColor
		},
		{
			variable: "--secondary-button-hover-color",
			value: secondaryButtonHoverColor
		},
		{
			variable: "--secondary-button-hover-background-color",
			value: secondaryButtonHoverBackgroundColor
		},
		{
			variable: "--secondary-button-border-radius",
			value: secondaryButtonBorderRadius
		},
		{
			variable: "--tooltip-button-color",
			value: tooltipButtonColor
		},
		{
			variable: "--tooltip-button-background-color",
			value: tooltipButtonBackgroundColor
		},
		{
			variable: "--tooltip-button-border-color",
			value: tooltipButtonBorderColor
		},
		{
			variable: "--tooltip-button-border-radius",
			value: tooltipButtonBorderRadius
		},
		{
			variable: "--tooltip-button-hover-color",
			value: tooltipButtonHoverColor
		},
		{
			variable: "--tooltip-button-hover-background-color",
			value: tooltipButtonHoverBackgroundColor
		},
		{
			variable: "--tooltip-button-hover-border-color",
			value: tooltipButtonHoverBorderColor
		}
	];

	cssVars.forEach((item) =>
		body.style.setProperty(item.variable, item.value ?? body.getPropertyValue(item.variable))
	);
};

export default changeCssVariables;
