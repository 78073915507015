import {
  SET_LOADING,
  TOGGLE_NOTIFICATION,
  REQUEST_TEST_DRIVE,
  GET_SHOWROOM_LIST,
  SET_SHOWROOM_LIST,
  GET_MODEL_FILTERS,
  GET_SHOWROOM_FILTERS,
  GET_VEHICLE_LIST,
  SET_MODEL_FILTERS,
  SET_SHOWROOM_FILTERS,
  SET_VEHICLE_LIST,
  SET_TO_RESCHEDULE,
  SET_CURRENT_PAGE,
  GET_DOCUMENT_TYPES,
  SET_DOCUMENT_TYPES,
  GET_TEST_DRIVES,
  SET_TEST_DRIVES,
  SET_USER_TEST_DRIVE_INFO,
  GET_PUBLIC_SETTINGS,
  SET_PUBLIC_SETTINGS,
  GET_DEALER_LIST,
  SET_DEALER_LIST
} from "./types";

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  isLoading
});

export const requestTestDrive = (params) => ({
  type: REQUEST_TEST_DRIVE,
  params
});

export const getShowroomList = (params) => ({
  type: GET_SHOWROOM_LIST,
  params
});

export const setShowroomList = (showroomList) => ({
  type: SET_SHOWROOM_LIST,
  showroomList
});

export const getDealerList = (params) => ({
  type: GET_DEALER_LIST,
  params
});

export const setDealerList = (dealerList) => ({
  type: SET_DEALER_LIST,
  dealerList
});

export const toggleNotification = (notificationValues) => ({
  type: TOGGLE_NOTIFICATION,
  notificationValues
});

export const getVehicleList = (params) => ({
  type: GET_VEHICLE_LIST,
  params
});

export const setVehicleList = (vehicleList) => ({
  type: SET_VEHICLE_LIST,
  vehicleList
});

export const getShowroomFilters = (params) => ({
  type: GET_SHOWROOM_FILTERS,
  params
});

export const setShowroomFilters = (showroomFilters) => ({
  type: SET_SHOWROOM_FILTERS,
  showroomFilters
});

export const getModelFilters = (params) => ({
  type: GET_MODEL_FILTERS,
  params
});

export const setModelFilters = (modelFilters) => ({
  type: SET_MODEL_FILTERS,
  modelFilters
});

export const setToReschedule = (newSchedule) => ({
  type: SET_TO_RESCHEDULE,
  newSchedule
});

export const setCurrentPage = (newPage) => ({
  type: SET_CURRENT_PAGE,
  newPage
});

export const getDocumentTypes = (params) => ({
  type: GET_DOCUMENT_TYPES,
  params
});

export const setDocumentTypes = (documentTypes) => ({
  type: SET_DOCUMENT_TYPES,
  documentTypes
});

export const getTestDrives = (params) => ({
  type: GET_TEST_DRIVES,
  params
});

export const setTestDrives = (testDrives) => ({
  type: SET_TEST_DRIVES,
  testDrives
});

export const setUserTestDriveInfo = (userTestDriveInfo) => ({
  type: SET_USER_TEST_DRIVE_INFO,
  userTestDriveInfo
});

export const loadPublicSettings = (code) => ({
  type: GET_PUBLIC_SETTINGS,
  code
});


export const getPublicSettings = (params) => ({
  type: GET_PUBLIC_SETTINGS,
  params,
});

export const setPublicSettings = (settings) => ({
  type: SET_PUBLIC_SETTINGS,
  settings
});