const getUtmParams = () => {
	const urlParameters = new URLSearchParams(window.location.search);
	let utmParams = {};
	const utmParamsArr = [];
	const utm_campaign = urlParameters.get("utm_campaign");
	const utm_source = urlParameters.get("utm_source");
	const utm_medium = urlParameters.get("utm_medium");
	const utm_content = urlParameters.get("utm_content");
	const utm_term = urlParameters.get("utm_term");

	if (utm_campaign) {
		utmParams.utmCampaign = utm_campaign;
		utmParamsArr.push(`utm_campaign=${utm_campaign}`);
	}
	if (utm_source) {
		utmParams.utmSource = utm_source;
		utmParamsArr.push(`utm_source=${utm_source}`);
	}
	if (utm_medium) {
		utmParams.utmMedium = utm_medium;
		utmParamsArr.push(`utm_medium=${utm_medium}`);
	}
	if (utm_content) {
		utmParams.utmContent = utm_content;
		utmParamsArr.push(`utm_content=${utm_content}`);
	}
	if (utm_term) {
		utmParams.utmTerm = utm_term;
		utmParamsArr.push(`utm_term=${utm_term}`);
	}

	let dataLayerParam = "";
	utmParams.websiteURL = window.parentOrigin || window.origin;
	if (utmParamsArr.length) dataLayerParam = `${utmParams.websiteURL}?${utmParamsArr.join("&")}`;

	return { payload: utmParams, dataLayerParam };
};

export default getUtmParams;
