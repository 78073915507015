import React from "react";
import "./header.css";
import Notification from "../../components/Notification/Notification";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const Header = ({ currentPage }) => {
  const { t } = useTranslation();
  return (
    <div className="header flex-column gap-3">
      <span className="lb-1">
        {currentPage === "schedule"
          ? getTranslation("Schedule Test Drive", t)
          : getTranslation("My Test Drives", t)}
      </span>
      <span>
        {currentPage === "schedule"
          ? getTranslation(
            "Select a filter to see the details of the models available for Test Drive.",
            t
          )
          : getTranslation("Consult - reschedule and/or cancel your test drive", t)}
      </span>
      {<Notification />}
    </div>
  );
};

export default Header;
