const orderBy = [
	{
		id: "vehicleName",
		name: "Vehicle"
	},
	{
		id: "showroomName",
		name: "Location"
	}
];

export default orderBy;
