import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from "@mui/material";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import "./multiselectcustom.css";

const MultiselectCustom = ({
  value,
  data,
  label,
  textField,
  name,
  onOpen,
  handleChange,
  showCount
}) => {
  const { t } = useTranslation();

  return (
    <FormControl className="multiselect" sx={{ m: 1, width: 300 }}>
      <InputLabel id="demo-multiple-checkbox-label">
        {getTranslation(label, t)}
      </InputLabel>
      <Select
        name={name}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={value}
        onOpen={() => onOpen(name)}
        renderValue={(selected) => {
          const names = data
            .filter((item) => selected.map(s => s[textField]).includes(item[textField]))
            .map((item) => item[textField]);
          return names.join(", ");
        }}
      >
       {data.map((dataItem, idx) => (
            <MenuItem key={idx}>
              <Checkbox
                    disableRipple
                    checked={
                      value.find((item) => item[textField] === dataItem[textField])
                        ? true
                        : false
                    }
                    onChange={() => handleChange(name, dataItem)}
                  />
              <ListItemText primary={ showCount ? `${dataItem[textField]} (${dataItem.count})` : `${dataItem[textField]}`}/>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default MultiselectCustom;
