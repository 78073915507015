import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Widget from "../pages/Widget/Widget";

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Widget />
    }
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
