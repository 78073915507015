import React from "react";
import "./muiInput.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const MuiInput = ({
  label,
  value,
  type,
  name,
  data,
  textField,
  handleChange,
  className = "",
  rows,
  error,
  defaultValue,
  handleOpen,
  valueField
}) => {
  const { t } = useTranslation();
  return (
    <div className={"input-types " + className + (error ? " error" : "")}>
      {type === "text" || type === "textArea" ? (
        <TextField
          className={type === "textArea" ? "textarea" : ""}
          id={type === "text" ? "standard-basic" : "outlined-multiline-static"}
          error={!value ? error : false}
          variant={type === "text" ? "standard" : "outlined"}
          label={getTranslation(label, t)}
          onChange={handleChange}
          name={name}
          value={value || ""}
          multiline={type === "text" ? false : true}
          rows={rows}
          helperText={
            type === "text"
              ? false
              : !value
                ? 0 + " / 100"
                : value?.length + " / 100"
          } //TODO: MAKE IT DYNAMIC
          defaultValue={defaultValue}
        />
      ) : (
        <FormControl variant="standard" error={!value ? error : false}>
          <InputLabel id="demo-simple-select-standard-label">
            {label}
          </InputLabel>
          <Select
            MenuProps={{
              autoFocus: false,
              disableAutoFocusItem: true,
              disableEnforceFocus: true,
              disableAutoFocus: true
            }}
            labelId="demo-simple-select-standard-label demo-simple-select-error"
            id="demo-simple-select-standard"
            value={value ?? ""}
            onOpen={handleOpen}
            onChange={handleChange}
            name={name}
          >
            {data?.map((datum) => (
              <MenuItem
                key={datum[valueField] || datum.value}
                value={datum[valueField] || datum.value}
              >
                {datum[textField]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {error && (
        <span>{getTranslation("Fill out this field correctly.", t)}</span>
      )}
    </div>
  );
};

export default MuiInput;
