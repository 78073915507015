const replaceAll = (input) => {
  if (!input) return;
  else return input.toLowerCase().replace(/[^\w]/g, "_");
};

export const getTranslation = (input, t) => {
  if (!t) return input;
  if (!input || input === null || input === undefined) return input;

  let translationKey = replaceAll(input);
  let translatedValue = t(translationKey);

  return translatedValue !== translationKey ? translatedValue : input;
};
