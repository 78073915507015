import getBrands from "./getBrands";

const brands = getBrands();

export const getMyTestDriveParams = (payload) =>
	`?documentTypeId=${payload.documentTypeId}&documentNumber=${
		payload.documentNumber
	}&email=${encodeURIComponent(payload.email)}&brands=${brands}`;

export const getVehiclesParams = (queryParams) => {
	const { take, date, showrooms, models, orderBy, vehicleId, brands, clientDateTime } = queryParams;

	return `?skip=0&take=${take}&brands=${brands}&bookingDate=${date}&showrooms=${showrooms}&models=${models}&orderBy=${orderBy}&vehicleId=${vehicleId}&clientDateTime=${clientDateTime}`;
};
