import React, { useEffect, useRef, useState } from "react";
import "./tooltipButton.css";
import { ClickAwayListener, Tooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";
import { BsFillTelephoneFill } from "react-icons/bs";
import InquiryModal from "../Modal/InquiryModal/InquiryModal";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { BsInfoCircleFill } from "react-icons/bs";

const TooltipButton = ({ text, toggleInfoModal }) => {
  const { t } = useTranslation();
  const mouseYPos = useRef();
  const [modalComponent, setModalComponent] = useState(null);
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [open, setOpen] = useState(false);
  const isInsideIframe = window.location !== window.parent.location;

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow
      placement='bottom'
    // open
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 188
    }
  });

  const toggleInquiryModal = (e) => {
    setShowInquiryModal(!showInquiryModal);
    if (isInsideIframe) mouseYPos.current = e.clientY;
  };

  useEffect(() => {
    if (isInsideIframe && showInquiryModal && modalComponent) {
      modalComponent.style.bottom = "auto";
      modalComponent.style.top = mouseYPos.current - 250 + "px";
    }
  }, [modalComponent, showInquiryModal, isInsideIframe]);

  return (
    <div className='tooltip-container'>
      <CustomWidthTooltip
        title={getTranslation("If no option fits your needs, we will contact you", t)}
      >
        <button
          className='secondary-btn'
          onClick={toggleInquiryModal}
        >
          <div className='d-flex align-items-center'>
            <BsFillTelephoneFill />
            {text}
          </div>
        </button>
      </CustomWidthTooltip>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={getTranslation("If no option fits your needs, we will contact you", t)}
          >
            <span
              className='mobile-tooltip'
              onClick={() => setOpen(true)}
            >
              <BsInfoCircleFill />
            </span>
          </Tooltip>
        </div>
      </ClickAwayListener>
      {showInquiryModal && (
        <InquiryModal
          setModalComponent={setModalComponent}
          toggleInfoModal={toggleInfoModal}
          toggleModal={toggleInquiryModal}
          open={showInquiryModal}
        />
      )}
    </div>
  );
};

export default TooltipButton;
