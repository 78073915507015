import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../utils/getTranslation";
import "./textCol.css";

const TextCol = ({ colName, data }) => {
  const { t } = useTranslation();
  return (
    <div className="text-col">
      <span className="heading">{getTranslation(colName, t)}</span>
      <span className="text">{data}</span>
    </div>
  );
};

export default TextCol;
